<template>
  <div class="content">
    <div class="top">
      <span>认证信息完善 服务更全面</span>
      <img src="@/assets/image/2.png" alt />
    </div>
    <div class="bottom">
      <van-cell-group>
        <van-field v-model="value" required label="用户手机号" readonly />
        <van-field
          v-model="verifyCode"
          required
          label="手机验证码"
          center
          clearable
          placeholder="请输入验证码"
        >
          <template #button>
            <span v-if="smstext" class="yzm" @click="getsms">{{smstext}}</span>
            <span class="yzm" v-else>倒计时{{time}}秒</span>
          </template>
        </van-field>
        <van-field label="您的公司名称" required v-model="companyName" placeholder="请输入公司名称" />
        <van-field label="法人姓名" required v-model="name" placeholder="请输入法人姓名" />
        <van-field label="法人身份证号" required v-model="representNumber" placeholder="请输入法人身份证号" />
      </van-cell-group>
      <van-cell-group class="updata">
        <van-cell label="上传清晰无残缺的营业执照">
          <template #title>
            <div>
              <span class="xxx">*</span>公司营业执照
            </div>
          </template>
        </van-cell>
        <van-uploader :after-read="afterRead">
          <img class="img" v-if="img" :src="img" alt />
          <div class="up" v-else>
            <van-icon name="plus" color="#ccc" size="30px" />
          </div>
        </van-uploader>
      </van-cell-group>
    </div>
    <div class="but" @click="submit">申请提交</div>
  </div>
</template>

<script>
import { file, sms, commitAuth } from '@/api/user'
import axios from 'axios'
import { Toast } from 'vant'
export default {
  data() {
    return {
      representNumber: '',
      name: '',
      companyName: '',
      value: '',
      verifyCode: '',
      img: '',
      smstext: '获取验证码',
      time: 60,
      open: true
    }
  },
  created() {
    this.value = this.$route.query.phone
  },
  methods: {
    async submit() {
      if (!this.verifyCode) {
        Toast('请输入手机验证码')
      } else if (!this.companyName) {
        Toast('请输入公司名称')
      } else if (!this.name) {
        Toast('请输入法人姓名')
      } else if (!this.representNumber) {
        Toast('请输入法人身份证')
      } else if (!this.img) {
        Toast('请上传公司营业执照')
      } else {
        const obj = {
          companyName: this.companyName,
          licenseImg: this.img,
          representName: this.name,
          representNumber: this.representNumber,
          verifyCode: this.verifyCode
        }
        this.throttle(obj)
      }
    },
    async throttle(obj) {
      if (this.open === true) {
        this.open = false
        const { data } = await commitAuth(obj)
        if (data.code === 200) {
          this.open = true
          Toast.success('已提交认证,请等待审核')
          setTimeout(() => {
            this.$router.push('/appointment')
          }, 1000)
        } else {
          this.open = true
          Toast(data.msg)
        }
      } else {
        Toast.fail('请勿重复提交表单')
      }
    },
    async getsms() {
      const { data } = await sms({
        phone: this.value
      })
      if (data.code === 200) {
        this.smstext = ''
        const interval = window.setInterval(() => {
          if (this.time-- <= 0) {
            this.time = 60
            this.smstext = '获取验证码'
            window.clearInterval(interval)
          }
        }, 1000)
      }
    },
    async afterRead(a) {
      console.log(a)
      const { data } = await file({
        fileName: a.file.name
      })
      data.data.body.file = a.file
      const formData = new FormData()
      for (const key in data.data.body) {
        formData.append(key, data.data.body[key])
      }
      axios({
        method: 'post',
        url: data.data.post,
        data: formData
      }).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.img = data.data.url
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.xxx {
  color: #ee0a24;
  font-size: 14px;
}
.content {
  width: 100%;
  // height: 100vh;
  padding-bottom: 15px;
  background-color: #fbfbfb;
  .updata .van-cell::after {
    border: none;
  }
  .up {
    border-radius: 5px;
    width: 130px;
    height: 80px;
    border: 1px #969696 dashed;
    margin-left: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img {
    width: 130px;
    height: 80px;
    border: 1px #969696 dashed;
    margin-left: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .bottom {
    overflow: hidden;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: right;
    span {
      font-size: 16px;
      color: #bebebe;
      width: 100px;
      position: absolute;
      top: 40%;
      left: 15vw;
    }
    img {
      width: 100vw;
    }
  }
  /deep/ .van-cell-group {
    .van-field__body {
      line-height: 27px;
    }
    .van-cell::after {
      transform: scaleY(1);
    }
  }
  .yzm {
    color: #f8892f;
  }
  .but {
    border: none;
    outline: none;
    width: 88%;
    margin-left: 6%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    background-color: #f8892f;
    font-size: 15px;
    border-radius: 25px;
    margin-top: 20px;
  }
}
</style>
